<template>
    <footer class=" text-white p-5" style="background-color:#000000;">
        <!-- Grid container -->
        <div class="container p-4">
            <!-- Section: Links -->
            <section class="">
                <!--Grid row-->
                <div class="row">
                    <!--Grid column-->
                    <div class="col-lg-3 col-md-6 mb-4 mb-md-0">
                        <h5 class="text-uppercase mb-1"><img src="../assets/logo_white.svg" alt=""></h5>
                        <ul class="list-unstyled mb-0">
                            <li>
                                <a href="#!" class="text-white">Money talks, purplepays </a>
                            </li>
                            <li>
                                <section class="mb-4">

                                    <!-- Twitter -->
                                    <a class="btn btn-primary btn-floating m-1" style="background-color: #55acee"
                                        href="https://twitter.com/Purplepay_app?t=bHlvbt_nCNLsJojjDIpwtA&s=09"
                                        role="button"><i class="fab fa-twitter"></i></a>
                                    <!-- Instagram -->
                                    <a class="btn btn-primary btn-floating m-1" style="background-color: #ac2bac"
                                        href="https://instagram.com/purplepay_app?igshid=YmMyMTA2M2Y=" role="button"><i
                                            class="fab fa-instagram"></i></a>
                                </section>
                            </li>
                        </ul>
                    </div>
                    <!--Grid column-->

                    <!--Grid column-->
                    <div class="col-lg-3 col-md-6 mb-4 mb-md-0">
                        <h5 class="text-uppercase mb-4">Product</h5>

                        <ul class="list-unstyled mb-0">
                            <li class="mb-3">
                                <router-link class="text-white " to="/cards">Cards</router-link>
                            </li>
                            <li class="mb-3">
                                <router-link class="text-white " to="/careers">Career</router-link>
                            </li>
                            <li class="mb-3">
                                <router-link class="text-white " to="/vendors">Vendor</router-link>
                            </li>
                        </ul>
                    </div>
                    <!--Grid column-->

                    <!--Grid column-->
                    <div class="col-lg-3 col-md-6 mb-4 mb-md-0">
                        <h5 class="text-uppercase mb-4">Company</h5>

                        <ul class="list-unstyled mb-0">
                            <li class="mb-3">
                                <a class="text-white " href="/docs">API Documentation</a>
                            </li>
                            <li class="mb-3">
                                <a class="text-white " href="https://purplepayapp.com/privacy-policy/">Privacy
                                    Policy</a>
                            </li>
                            <li class="mb-3">
                                <a class="text-white" href="https://purplepayapp.com/terms-and-conditions/">Terms
                                    and Condition</a>
                            </li>
                            <li>
                                <a class="text-white" href="https://purplepayapp.com/aml-policy/">AML Policy</a>
                            </li>
                        </ul>
                    </div>
                    <!--Grid column-->



                    <!--Grid column-->
                    <div class="col-lg-3 col-md-6 mb-4 mb-md-0">
                        <h5 class="text-uppercase mb-4">Contacts us</h5>

                        <ul class="list-unstyled mb-0">
                            <li class="mb-3">
                                <a class="text-white"
                                    href="mailto:Support@purplepayapp.com">Support@purplepayapp.com</a>
                            </li>
                        </ul>
                    </div>
                    <!--Grid column-->
                </div>
                <!--Grid row-->
            </section>
            <!-- Section: Links -->

        </div>
        <!-- Grid container -->

        <!-- Copyright -->
        <br />
        <div class="text-center p-3" style="background-color: rgba(0, 0, 0, 0.2)">
            © 2023 Copyright:
            <a class="text-white" href="#">Purplepay</a>
        </div>
        <!-- Copyright -->

    </footer>
</template>

<script>
export default {

}
</script>

<style scoped>

</style>